<!--
このTagRendererではブログファイルに書かれた情報に基づいて内容を生成する
-->
<template>
  <template v-for="(tag,index) in blogPageTags" :key="tag.type+'--'+index">
    <div v-if="tag.type==='paragraph'" :class="tag.style['class']||''" :style="expandTag(tag.style)">
      <template v-if="tag.useRaw===false">{{tag.text}}</template>
      <span v-else v-html="tag.text"></span>
    </div>
    <div  v-else-if="tag.type==='image'">
      {{tag.caption?tag.alt+":":""}}<br/>
      <img :class="`rounded img-fluid ${tag.style['class']||''}`"
        :style="expandTag(tag.style)"
        :src="convertToAssets(tag.url)"
        :alt="tag.alt"
      />
    </div>
  </template>
</template>

<script>
export default {
  name:"TagRenderer",
  props:{
    blogPageTags:{
      default:function(){
        return [];
      }
    }
  },
  methods:{
    expandTag:function(tags){
        let list=Object.keys(tags);
        return list.filter(x=>x!=="class").map(x=>`${x}:${tags[x]}`).join(";");
    }
  }
}
</script>

<style lang="css" scoped>
</style>
