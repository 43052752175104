<!--
このファイルは独自の出力はありません
/<name> /<special-tags> が同じものだと処理されてしまうので特別な処理を施す
-->
<template lang="html">
  <div v-if="error" class="container-md text-center page" style="margin:0">
    {{strings.server_error_txt}}
  </div>
  <template v-if="resolved">
    <blogPage v-if="valid_page" :url="`/${name}`"/>
    <PostList v-else-if="tag_name" :tag="tag_name"/>
    <div v-else class="container-md text-center page" style="margin:0">
      {{strings.page_not_found_txt}}
    </div>
  </template>
</template>

<script>
import blogPage from "@/components/blogPage.vue";
import PostList from "@/components/PostList.vue";
export default {
  name:"PostTagRouter",
  props:["name"],
  components:{
    blogPage:blogPage,
    PostList:PostList
  },
  data:function(){
    return {
      resolved:false,
      valid_page:false,
      error:false
    }
  },
  watch:{
    name:function(){
      this.checkValidity();
    }
  },
  methods:{
    checkValidity:async function(){
      if(this.name==="posts"){
        this.resolved=true;
        this.valid_page=false; //// BUG: IDK
        return;
      }
      this.resolved=false;
      this.valid_page=false;
      this.error=false;
      try{
        this.valid_page=(await fetch(this.convertToAssets(`/${this.name}.json`,"/apis/"),{method:"HEAD"})).ok;
        this.resolved=true;
      }
      catch(e){
        this.error=true;
      }
    }
  },
  computed:{
    tag_name:function(){
      return this.name==="posts"?"posts":this.getNavigationBar().links[this.name];
    }
  },
  mounted:function(){
    this.checkValidity();
  },
  inject:['getNavigationBar']
}
</script>

<style lang="css" scoped>
</style>
