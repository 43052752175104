import { createRouter, createWebHistory } from 'vue-router'
import blogPage from '../components/blogPage.vue'
import PostList from '../components/PostList.vue'
import PostTagRouter from './PostTagRouter.vue'

const routes = [
  {
    //main page
    path: '/',
    name: 'Home',
    component: blogPage
  },
  {
    //post pages
    path:"/posts/:page(\\d+)?",
    name:"Posts",
    component:PostList,
    props:function(route){
      let page=0;
      if(route.params.page){
        page=parseInt(route.params.page)-1;
      }
      return {page:page};
    }
  },
  {
    //posts pages under ta
    path:"/tags/:tag/:page(\\d+)?",
    name:"TagPosts",
    component:PostList,
    props:function(route){
      let page=0;
      if(route.params.page){
        page=parseInt(route.params.page)-1;
      }
      return {
        page:page,
        tag:route.params.tag
      };
    }
  },
  {
    //posts pages under ta
    path:"/:tag/:page(\\d+)?",
    name:"TagPostsPinned",
    component:PostList,
    props:function(route){
      let page=0;
      if(route.params.page){
        page=parseInt(route.params.page)-1;
      }
      return {
        page:page,
        tag:route.params.tag,
        convert:true
      };
    }
  },
  {
    //ambigious case between content and posts
    path:"/:name/",
    name:"PostTagRouter",
    component:PostTagRouter,
    props:function(route){
      return {
        name:route.params.name
      };
    }
  },
  {
    //direct link to posts under a CAT
    path:"/:pathMatch(.*)",
    name:"Posts",
    component:blogPage,
    props:function(route){
      return {url:route.params.pathMatch};
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
