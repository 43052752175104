<!--
これはリストアイテムである
-->
<template lang="html">
  <div class="card" v-if="item.title">
    <div class="card-body">
      <h4 class="card-title">  <router-link :to="item.path" class="card-link">{{item.title}}</router-link></h4>
      <h6 v-if="item.properties['hide-timestamp']!=='true'">
        {{strings.last_update}} {{new Date(item.timestamp*1000).toLocaleString("ja-JP",{timezone:"Tokyo"})}}
      </h6>
      <div v-if="item.properties['hide-tags']!=='true'">
          {{strings.tags}}
          <span
            class="badge badge-secondary"
            style="margin-right:10px"
            v-for="tag in item.tag"
            :key="tag+item.timestamp"
          >
          <router-link :to="resolveTagLink(tag)" class="text-white">{{tag}}</router-link>
        </span>
      </div>
      <router-link :to="item.path" class="card-link">{{strings.read_more}}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name:"blogListItem",
  props:{
    item:{
      default:function(){return {}}
    }
  },
  methods:{
    resolveTagLink:function(tag){
      const nav=this.getNavigationBar();
      for(let i=0;i<nav.linkNames.length;i++){
        let linkName=nav.linkNames[i];
        if(tag===nav.links[linkName]){
          return `/${linkName}`;
        }
      }
      return `/tags/${tag}`;
    }
  },
  inject:['getNavigationBar']
}
</script>

<style lang="css" scoped>
</style>
