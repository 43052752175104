import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app=createApp(App); //create the app
//define global exports
//strings
app.config.globalProperties.strings=require("./config/strings.js");
//apis
app.config.globalProperties.convertToAssets=(url,extension="")=>`${process.env.NODE_ENV === 'development'?"http://localhost:8081/":extension}${url}`.replace(/(?<!(http:|https:))\/\//m,"/");
//globals
app.config.globalProperties.list=undefined;
app.use(router).mount('#app')
