<template>
  <Navigation ref="nav"/>
  <router-view/>
  <Footer/>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import Footer from "./components/Footer.vue";
export default {
  components: {
    Navigation:Navigation,
    Footer:Footer
  },
  methods:{
    getNavigationBar:function(){
      return this.$refs.nav;
    }
  },
  provide:function(){
    return {
      getNavigationBar:this.getNavigationBar,
      setPageTitle:function(title){
        return document.title=`${title?title+"-":""}${this.strings.blogTitle}`;
      }
    }
  }
};
</script>
<style>
</style>
