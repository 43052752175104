<template lang="html">
  <footer class="jumbotron jumbotron-fluid bg-primary text-white" style="margin:0;padding-top:0px">
    <div class="container text-center align-self-center">
        &copy;{{strings.copyright}}
    </div>
  </footer>
</template>

<script>
export default {
  name:"Footer"
}
</script>
