<template lang="html">
  <div class="container page" style="">
    <template v-if="!error">
      <TitleBinder :title="tag_name"/>
      <blogListItem v-for="name in listRendering" :key="name+'-card'" :item="list[name]"/>
      <ul class="pagination justify-content-center">
        <li class="page-item"><router-link :class="`page-link ${page<=0?'disabled':''}`" :to="`${baseUrl}${page+1>1?page:''}`">&lt;&lt;</router-link></li>
        <li class="page-item"><a class="page-link disabled" href="#">{{page+1}}</a></li>
        <li class="page-item"><router-link :class="`page-link ${page>=pages-2?'disabled':''}`" :to="`${baseUrl}${page+1<pages?page+2:''}`">&gt;&gt;</router-link></li>
      </ul>
    </template>
    <template v-else>
      <TitleBinder :title="strings.server_error"/>
      <p>{{strings.server_error_txt}}</p>
    </template>
  </div>
</template>

<script>
import blogListItem from "./blogListItem.vue"
import TitleBinder from "./TitleBinder.vue"
export default {
  name:"PostList",
  components:{
    blogListItem,
    TitleBinder
  },
  methods:{
    fetchData:async function(){
      try{
        this.error=false;
        if(!this.list){
          const req=await fetch(this.convertToAssets("posts.json","/apis/"));
          if(!req.ok)throw "HTTP error";
          const json=await req.json();
          this.list=json;
        }
        this.ready=true;
      }
      catch(e){
        console.error(e);
        this.error=true;
      }
    }
  },
  data:function(){
    return {
      error:false,
      ready:false
    }
  },
  computed:{
    resolvedKeys:function(){
      return this.ready?this.list.__keys:[];
    },
    activeList:function(){
      let array=this.resolvedKeys;
      if(!array)return [];
      array=array.filter(x=>this.list[x].properties["dont-enumerate"]!=="true");
      if(this._tag!=="posts"){
        array=array.filter(x=>this.list[x].tag.includes(this._tag)&&this.list[x].properties["hide-tags"]!=="true");
      }
      return array;
    },
    pages:function(){
      return this.activeList.length/5+(this.activeList.length%5!==0?1:0);
    },
    listRendering:function(){
      const base=this.page*5;
      return this.activeList.slice(base,base+5);
    },
    baseUrl:function(){
      this.getNavigationBar().currentPage=this._tag;
      if(this._tag==="posts"){
        return "/posts/";
      }
      const nav=this.getNavigationBar();
      for(let i=0;i<nav.linkNames.length;i++){
        let linkName=nav.linkNames[i];
        if(this._tag===nav.links[linkName]){
          this.getNavigationBar().currentPage=linkName;
          return `/${linkName}/`;
        }
      }
      return `/tags/${this._tag}/`;
    },
    tag_name:function(){
      return this._tag==="posts"?this.strings.posts:`#${this._tag}`
    },
    _tag:function(){
      const nav=this.getNavigationBar();
      return this.convert&&this.tag!=="posts"?nav.links[this.tag]:this.tag
    }
  },
  props:{
    tag:{
      default:function(){return "posts";}
    },
    page:{
      default:function(){return 0;}
    },
    convert:{
      default:function(){return false}
    }
  },
  created:function(){
    this.fetchData();
  },
  watch: {
    page() {
      // HACK: Force client view to reseted
      window.scrollTo(0,0);
    }
  },
  inject:['getNavigationBar',"setPageTitle"]
}
</script>

<style lang="css" scoped>
</style>
