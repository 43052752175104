<template lang="html">
  <div class="jumbotron jumbotron-fluid bg-primary text-white" style="margin:0;padding-top:15px">
    <div class="container">
      <h1><router-link to="/" style="text-decoration:none;color:white">{{strings.blogTitle}}</router-link></h1>
      <p v-if="strings.blogDesc">{{strings.blogDesc}}</p>
      </div>
    </div>

  <nav class="navbar navbar-expand-sm navbar-dark bg-primary">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav">
        <li class="nav-item">
          <router-link :class="expandStyleString('index')" to="/" v-on:click="currentPage=''">{{strings.home}}</router-link>
        </li>
        <li class="nav-item">
          <router-link :class="expandStyleString('posts')" to="/posts" v-on:click="currentPage=''">{{strings.posts}}</router-link>
        </li>
        <li v-for="linkKey in linkNames" :key="linkKey" class="nav-item">
          <router-link :class="expandStyleString(linkKey)" :to="'/'+linkKey" v-on:click="currentPage=''">{{links[linkKey]}}</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name:"Nagivation",
  methods:{
    fetchData:async function(){
      try{
        const req=await fetch(this.convertToAssets("meta.json","/apis/"));
        if(!req.ok)throw "HTTP error";
        const json=await req.json();
        this.links=json;
      }
      catch(e){
        console.error(e);
      }
    },
    expandStyleString:function(id){
      return `nav-link ${id===this.currentPage?"active":""}`
    }
  },
  computed:{
    linkNames:function(){
      return Object.keys(this.links??{});
    }
  },
  data:function(){
    return{
      links:{},
      currentPage:""
    }
  },
  created:function(){
    this.fetchData();
  }
}
</script>

<style lang="css" scoped>
</style>
