<!--
これはブログ内容をレンダリングするためのコード
-->
<template>
  <div class="container page" style="">
    <div class="row">
      <div class="col-sm-1 d-none-sm">
      </div>
      <div class="col-sm-7 p-3 post-text">
        <template  v-if="!error&&blogPageTags.length>0">
          <TitleBinder :title="blog.title"/>
          <h1>{{blog.title}}</h1>
          <h6 v-if='blog.properties["hide-timestamp"]!=="true"'>{{strings.last_update}} {{new Date(blog.timestamp*1000).toLocaleString("ja-JP",{timezone:"Tokyo"})}}</h6>
          <TagRenderer :blogPageTags="blogPageTags"/>
          <div v-if="blog.properties['hide-tags']!=='true'">
              {{strings.tags}}
              <span
                class="badge badge-secondary"
                style="margin-right:10px"
                v-for="tag in blog.tag"
                :key="tag+blog.timestamp"
              >
              <router-link :to="resolveTagLink(tag)" class="text-white">{{tag}}</router-link>
            </span>
          </div>
          <div v-if="blog.properties['dont-enumerate']!=='true'">
            <router-link :to="next_link" :class="next_class">{{strings.next_post}}</router-link>
            <router-link :to="prev_link" :class="prev_class">{{strings.prev_post}}</router-link>
          </div>
        </template>
        <template v-else-if="error">
          <TitleBinder :title="strings.server_error"/>
          <p>{{strings.server_error_txt}}</p>
        </template>
      </div>
      <div class="col-sm-4 p-3">
        <SideBar v-if="url!='/'&&!error"/>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TagRenderer from '@/components/TagRenderer.vue'
import SideBar from '@/components/SideBar.vue'
import TitleBinder from '@/components/TitleBinder.vue'

export default {
  name: 'blogPage',
  props:{
    url:{
      default:function(){
        return "/";
      }
    }
  },
  components: {
    TagRenderer,
    SideBar,
    TitleBinder
  },
  methods:{
    fetchData:async function(){
      try{
        this.error=false;
        //first the list
        if(!this.list)
        {
          const req=await fetch(this.convertToAssets("posts.json","/apis/"));
          if(!req.ok)throw "HTTP error";
          const json=await req.json();
          this.list=json;
        }
        //them load the posts
        // HACK: this is needed because it break the pagination
        {
          const req=await fetch(this.convertToAssets(`${this.url==="/"?"index":this.url}.json`,"/apis/"));
          if(!req.ok)throw "HTTP error";
          const json=await req.json();
          this.blog=json;
        }
        //update the navigation bar
        let pageId="";
        if(this.url==="/"){
          pageId="index";
        }
        else if(this.blog.properties["show-in-nav"]){
          //for items shown in nav its name is the id
          pageId=this.url.slice(1);
        }
        else{
          pageId=this.blog.properties["base"].slice(1);
        }
        this.getNavigationBar().currentPage=pageId;

      }
      catch(e){
        console.error(e);
        this.error=true;
      }
    },
    resolveTagLink:function(tag){
      const nav=this.getNavigationBar();
      for(let i=0;i<nav.linkNames.length;i++){
        let linkName=nav.linkNames[i];
        if(tag===nav.links[linkName]){
          return `/${linkName}`;
        }
      }
      return `/tags/${tag}`;
    }
  },
  data:function(){
    return {
      blog:{},
      error:false
    }
  },
  computed:{
    blogPageTags:function(){return this.blog.pageTags??[];},
    entry_key:function(){
      const arr=this.url.split("/");
      return arr[arr.length-1];
    },
    prev_link:function(){
      if(!this.list)return "#";
      const idx=this.list.__keys.indexOf(this.entry_key);
      console.log(idx);
      if(idx<this.list.__keys.length-1){
        const name=this.list.__keys[idx+1];
        const obj=this.list[name];
        return `/${obj.properties.base}/${name}`;
      }
      else{
        return "#";
      }
    },
    next_link:function(){
      if(!this.list)return "#";
      const idx=this.list.__keys.indexOf(this.entry_key);
      if(idx>0){
        const name=this.list.__keys[idx-1];
        const obj=this.list[name];
        return `/${obj.properties.base}/${name}`;
      }
      else{
        return "#";
      }
    },
    prev_class:function(){
      return `${this.prev_link==="#"?"disabled":""} float-md-right`
    },
    next_class:function(){
      return `${this.next_link==="#"?"disabled":""} float-md-left`
    },
  },
  mounted:function(){
    this.fetchData();
  },
  watch: {
    url:function(){
      this.fetchData();
      // HACK: Force client view to reseted
      window.scrollTo(0,0);
    }
  },
  inject:['getNavigationBar']
}
</script>
