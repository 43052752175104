<template lang="html">
  <template v-if="!hide">
    <h3>{{strings.about_author}}</h3>
    <img :src="convertToAssets(strings.author_pp)" class="rounded img-fluid" style="max-height:250px;" :alt="strings.author_name">
    <h3>{{strings.author_name}}</h3>
    {{strings.author_desc}}
  </template>
</template>

<script>
export default {
  name:"SideBar",
  props:{
    hide:{
      default:false,
    }
  }
}
</script>

<style lang="css" scoped>
</style>
