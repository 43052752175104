<template>
  <slot></slot>
</template>
<script>
export default{
  name:"TitleBinder",
  props:["title"],
  watch:{
    title:function(){
      this.setPageTitle(this.title);
    }
  },
  mounted:function(){
    this.setPageTitle(this.title);
  },
  inject:["setPageTitle"]
}
</script>
